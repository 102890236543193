<nz-layout style="height: 100%;">
  <div class="header">
    <img class="logo" src="assets/images/movisat.png" alt="Logo">
    <div class="salir-container">
      <a nz-dropdown [nzDropdownMenu]="menu" class="salir-link">
        <div class="user-info">
          <img width="24px" height="24px" class="user-icon" src="assets/images/usuario.png" alt="User Icon">
          <div class="nombreUsu" title="{{usuario.Nombre}}">
            {{usuario.Nombre}}
          </div>
          <i nz-icon nzType="down"></i>
        </div>
      </a>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>{{langService.translate(langService.INFORMACION)}}</li>
          <li nz-menu-item (click)="onCloseSession()">{{langService.translate(langService.CERRAR_SESION)}}</li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>

     <!-- label con el nombre del proyecto -->
     <div class="nombreproyecto" *ngIf="isSiderCollapsed">
      <span>{{empresaSelect.Nombre}}</span>
    </div>
  <nz-content class="outer-content" style="overflow: hidden;">
    <nz-layout class="inner-layout">
      <nz-sider [nzWidth]="isSiderCollapsed ? '0px' : anchoEmpresas" nzTheme="light"
        [style]="{'margin-left': anchoEmpresasMargenIzqMovil}">
        <div *ngIf="!isSiderCollapsed"
          style="color:dodgerblue; padding-left: 17px; background-color: #f0f2f5; font-size: 120%;">
          {{langService.translate(langService.PROYECTOS)}}
        </div>
        <div *ngIf="!isSiderCollapsed" style=" margin-top: 3px;">
          <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="small">
            <input nz-input (input)="onInput($event)" [(ngModel)]="value" (click)="click($event)"
              [placeholder]="'Buscar'" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </div>
        <li *ngIf="!isSiderCollapsed" nz-menu class="scroll-empresas">
          <ul #emp *ngFor="let empresa of results; index as i">
            <li #listEmpresas *ngIf="i==0;else other_content" nz-menu-item [attr.data-index]="i" [nzSelected]="'true'"
              (click)="onSelectEmpresa(empresa)">
              {{empresa.Nombre}}
            </li>
            <ng-template #other_content>
              <li nz-menu-item [attr.data-index]="i" (click)="onSelectEmpresa(empresa)">
                {{empresa.Nombre}}
              </li>
            </ng-template>
          </ul>
        </li>
      </nz-sider>

      <div class="content" style="width: 100%;">
        <div class="tab" style="display: flex; align-items: center;gap:5px">
          <!-- Botón burger a la izquierda de las pestañas -->
          <button class="burger-menu" (click)="toggleSider()">
            <!-- Cambia el ícono dinámicamente según el estado de 'isSiderCollapsed' -->
            <span nz-icon [nzType]="isSiderCollapsed ? 'menu' : 'close'" nzTheme="outline"></span>
          </button>

          <button class="tablinks" (click)="selectTab('Aplicaciones')"
            [class.active]="activeTab === 'Aplicaciones'">Aplicaciones</button>
          <button class="tablinks" (click)="selectTab('Herramientas')"
            [class.active]="activeTab === 'Herramientas'">Herramientas Internas</button>
        </div>

        <div [ngClass]="{'tabcontent': true, 'active': activeTab === 'Aplicaciones'}" id="Aplicaciones">
          <div class="subtab">
            <button class="tablinks" (click)="selectSubTab('TodasApps')"
              [class.active]="activeSubTab === 'TodasApps'">Todo</button>
            <button class="tablinks" (click)="selectSubTab('Apps')"
              [class.active]="activeSubTab === 'Apps'">Apps</button>
            <button class="tablinks" (click)="selectSubTab('Clientes')"
              [class.active]="activeSubTab === 'Clientes'">Clientes</button>
            <button class="tablinks" (click)="selectSubTab('Ciudadano')"
              [class.active]="activeSubTab === 'Ciudadano'">Ciudadano</button>
          </div>

          <div [ngClass]="{'subtabcontent': true, 'active': activeSubTab === 'TodasApps'}" id="TodasApps">
            <app-list-card [accesos]="accesos" [usuario]="usuario"></app-list-card>
          </div>
          <div [ngClass]="{'subtabcontent': true, 'active': activeSubTab === 'Apps'}" id="Apps">
            <app-list-card [accesos]="accesos" [usuario]="usuario"></app-list-card>
          </div>
          <div [ngClass]="{'subtabcontent': true, 'active': activeSubTab === 'Ciudadano'}" id="Ciudadano">
            <app-list-card [accesos]="accesos" [usuario]="usuario"></app-list-card>
          </div>
        </div>

        <div [ngClass]="{'tabcontent': true, 'active': activeTab === 'Herramientas'}" id="Herramientas">
          <app-list-card [accesos]="accesos" [usuario]="usuario"></app-list-card>
        </div>
      </div>
    </nz-layout>
  </nz-content>
  <nz-footer>
    Copyright <a href="http://www.movisat.com" target="_blank">MOVISAT</a> © {{anno}} | ver. {{verSoft}} |
    {{entorno}}
  </nz-footer>
</nz-layout>
import { BaseModel } from "./base.model";
import { RolModel } from "./rol.model";

export class AplicacionModel extends BaseModel {
    public Id: number;
    public Nombre: string;
    public Imagen: string;
    public NoWeb: boolean;
    public Ciudadano: boolean;
    public Interna: boolean;

    public AplicacionModel(id: number, nombre: string, imagen: string, noWeb: boolean,
      ciudadano: boolean, interna: boolean,  fechaAlta: Date, fechaBaja: Date, fechaModificacion: Date) {
        this.Id = id;
        this.Nombre = nombre;
        this.Imagen = imagen;
        this.NoWeb = noWeb;
        this.Ciudadano = ciudadano;
        this.Interna = interna;
        this.FechaAlta = fechaAlta;
        this.FechaBaja = fechaBaja;
        this.FechaModificacion = fechaModificacion;
    }
}
